import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import { Spinner, Alert } from "reactstrap";
import UserNavbar from "../../Navbar/UserNavbar";
import DashboardHeader from "./DashboardHeader";
import { Link } from "react-router-dom";

const WithdrawFund = () => {
  const [apptoken, setapptoken] = useState(process.env.REACT_APP_APPTOKEN);
  const [endpoint, setendpoint] = useState(process.env.REACT_APP_ENDPOINT);

  const [load, setload] = useState(false);

  const [usertoken, setusertoken] = useState(
    localStorage.getItem("eclusertoken")
  );
  const [amount, setamount] = useState("");
  const [bname, setbname] = useState("");
  const [acctname, setacctname] = useState("");
  const [acctnum, setacctnum] = useState("");
  const [alertt, setalertt] = useState("");
  const [showalert, setshowalert] = useState(false);
  const [walletbalance, setwalletbalance] = useState("");
  let history = useHistory();

  const WalletPayment = (e) => {
    if ((usertoken, amount, bname, acctname, acctnum)) {
      setload(true);

      const data = new FormData();
      data.append("usertoken", usertoken);
      data.append("amount", amount);
      data.append("bname", bname);
      data.append("acctname", acctname);
      data.append("acctnum", acctnum);
      data.append("apptoken", apptoken);

      axios
        .post(`${endpoint}/v1/user-withdraw`, data, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.success === true) {
            setusertoken(response.data.usertoken);
            setshowalert(true);
            setalertt(response.data.message);
            setload(false);
            alert(response.data.message);
            history.push(`/dashboard`);
          } else {
            setshowalert(true);
            setalertt(response.data.message);
            setload(false);
          }
        })
        .catch((error) => {
          setshowalert(true);
          setalertt(error.name, "Check your Network Connection!!!");
          setload(false);
        });
    } else {
      setshowalert(true);
      setalertt("Pls, Input Account Details and Try Again!!!");
      setload(false);
    }
    e.preventDefault();
  };

  const fetchBal = () => {
    const data = {
      apptoken: apptoken,
      usertoken: usertoken,
    };
    axios
      .get(`${endpoint}/v1/get-wallet-balance`, {
        params: data,
      })
      .then((response) => {
        if (response.data.success === false) {
        } else {
          setwalletbalance(response.data.walletbalance);
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };
  useEffect(() => {
    fetchBal();
  }, 0);

  return (
    <>
      <div className="send-package">
        <div className="container">
          <UserNavbar />
          <section className="fundwallet">
            <DashboardHeader />

            <div
              className="col-md-10 py-4 white ml-auto mr-auto shadow"
              style={{ borderRadius: "1rem" }}
            >
              {/* <div className="container"> */}
              <h5 className="text-center ">WITHDRAW FUND </h5>

              {/* {load ? (
                <div className="text-center">
                  <Spinner color="success" />
                  <h5 className="font-weight-light">
                    Please wait for your transaction is processing{" "}
                  </h5>
                  <h6> Do not refresh page!!! </h6>
                </div>
              ) : (
                <>
                  <div className="form">
                

                    <form>
                      <div className="row justify-content-center">
                        <div className="col-md-6 text-center">
                          <label> Amount </label>

                          <div className="input-group">
                            <input
                              type="number"
                              className=" input-style"
                              placeholder="Enter Amount"
                              onChange={(e) => setamount(e.target.value)}
                              value={amount}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      
                      <div class="ml-auto mr-auto text-center">
                        {amount < walletbalance ? (
                          <>
                            <Link to="/dashboard/fund-wallet">
                              <button
                                type="button"
                                class="btn btn-danger  btn my-0"
                              >
                                Fund Wallet
                              </button>
                            </Link>
                          </>
                        ) : (
                          <>
                            {issending ? (
                              <>
                                <button
                                  type="button"
                                  class="btn btn-success btn my-0"
                                  disabled
                                >
                                  processing <Spinner color="light" />
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  type="button"
                                  class="btn btn-success my-0"
                                  onClick={(e) => WalletPayment(e)}
                                >
                                  pay ₦
                                </button>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </form>
                  </div>
                </>
              )} */}

              {load ? (
                <div className="text-center">
                  <Spinner color="success" />
                  <h5 className="font-weight-light">
                    Please wait for your transaction is processing{" "}
                  </h5>
                  <h6> Do not refresh page!!! </h6>
                </div>
              ) : (
                <>
                  <div className="form">
                    <form>
                      <div className="row justify-content-center">
                        <div className="col-md-6 ">
                          <label> Amount </label>

                          <div className="input-group">
                            <input
                              type="number"
                              className=" input-style"
                              placeholder="Enter Amount"
                              onChange={(e) => setamount(e.target.value)}
                              value={amount}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6 ">
                          <label> Bank Name </label>

                          <div className="input-group">
                            <input
                              type="text"
                              className=" input-style"
                              placeholder="Enter Bank Name"
                              onChange={(e) => setbname(e.target.value)}
                              value={bname}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6 ">
                          <label> Account Name </label>

                          <div className="input-group">
                            <input
                              type="text"
                              className=" input-style"
                              placeholder="Enter Account Name"
                              onChange={(e) => setacctname(e.target.value)}
                              value={acctname}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6 ">
                          <label> Account Number </label>

                          <div className="input-group">
                            <input
                              type="number"
                              className=" input-style"
                              placeholder="Enter Account Number"
                              onChange={(e) => setacctnum(e.target.value)}
                              value={acctnum}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      {showalert ? (
                        <>
                          <Alert color="success">{alertt}</Alert>
                        </>
                      ) : (
                        <></>
                      )}
                      <div class="ml-auto mr-auto text-center">
                        {Number(amount) > Number(walletbalance) ? (
                          <>
                            {/* <Link to="/dashboard/fund-wallet">
                              <button
                                type="button"
                                class="btn btn-danger  btn my-0"
                              >
                                Fund Wallet
                              </button>
                            </Link> */}
                            <Alert color="success">
                              Sorry!, You do not have sufficient Account Balance to process this Withdrawal.
                            </Alert>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              class="btn btn-success my-0"
                              onClick={(e) => WalletPayment(e)}
                            >
                              Submit
                            </button>
                          </>
                        )}
                      </div>
                    </form>
                  </div>
                </>
              )}
              {/* </div> */}
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default WithdrawFund;
