import React, { useState, useEffect } from "react";
import axios from "axios";
import { Spinner, Alert } from "reactstrap";
import AdminNavbar from "../../Navbar/AdminNavbar";
import { useHistory } from "react-router-dom";

const EditFood = (props) => {
  const [apptoken, setapptoken] = useState(process.env.REACT_APP_APPTOKEN);
  const [endpoint, setendpoint] = useState(process.env.REACT_APP_ENDPOINT);

  const [foodname, setfoodname] = useState(props.location.state.title);
  const [description, setdescription] = useState(props.location.state.des);
  const [price, setprice] = useState(props.location.state.price);
  const [foodid, setfoodid] = useState(props.location.state.id);

  let history = useHistory();

  const [issending, setissending] = useState(false);
  const [showalert, setshowalert] = useState(false);
  const [alertt, setalert] = useState("");

  function FoodEdit(e) {
    if ((foodname, foodid, description, price)) {
      setissending(true);

      const data = new FormData();
      data.append("fid", foodid);
      data.append("title", foodname);
      data.append("description", description);
      data.append("price", price);
      data.append("apptoken", apptoken);

      axios
        .post(`${endpoint}/v1/admin-edit-food`, data, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((res) => {
          

          if (res.data.success === true) {
            setshowalert(true);
            setalert(res.data.message);
            setissending(false);
            // window.location.reload(true);
            history.push('/admin/food')
            alert(res.data.message)
            
          } else {
            setshowalert(true);
            setalert(res.data.message, "error");
            setissending(false);
            
          }
        })
        .catch((error) => {
          
          setshowalert(true);
          setalert("Check your Network Connection!!!");
          setissending(false);
        });
    } else {
      setshowalert(true);
      setalert("Empty fields, Check form again!");
      setissending(false);
    }
    e.preventDefault();
  }
  

  return (
    <>
      <AdminNavbar />
      <section className="add-food">
        <div className="container">
          <div className="send">
            <div className="text-center">
              <h5>
                {" "}
                EDIT FOOD - <span className="green-text">{foodname}</span>{" "}
              </h5>
            </div>
            <hr />
            <div className="form">
              <form>
                <div className="row justify-content-center">
                  <div className="col-md-10 ">
                    <label> Food: </label>

                    <div className="input-group">
                      <input
                        type="text"
                        className=" input-style"
                        placeholder="Food Name *"
                        onChange={(e) => setfoodname(e.target.value)}
                        value={foodname}
                      />
                    </div>
                  </div>

                  <div className="col-md-10 ">
                    <label> Price: </label>

                    <div className="input-group">
                      <input
                        type="number"
                        className=" input-style"
                        placeholder="Food Price *"
                        onChange={(e) => setprice(e.target.value)}
                        value={price}
                      />
                    </div>
                  </div>

                  <div className="col-md-10 ">
                    <label> Description: </label>

                    <div className="input-group">
                      <textarea
                        type="text"
                        className="input-style textarea-style"
                        placeholder="Description *"
                        onChange={(e) => setdescription(e.target.value)}
                        value={description}
                      ></textarea>
                    </div>
                  </div>

                  <div class="col-md-10  mx-auto text-center">
                    {showalert ? (
                      <>
                        <Alert color="success">{alertt}</Alert>
                      </>
                    ) : (
                      <></>
                    )}
                    <div class="user-btn mb-4 mr-auto text-center">
                      {issending ? (
                        <>
                          <button
                            type="button"
                            class="btn btn-dark shadow waves-effect"
                            disabled
                          >
                            <strong>
                              editing <Spinner color="light" />
                            </strong>
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="button"
                            class="btn btn-dark shadow waves-effect"
                            action="submit"
                            onClick={(e) => FoodEdit(e)}
                          >
                            <strong> edit </strong>
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <hr />
          </div>
        </div>
      </section>
    </>
  );
};

export default EditFood;
