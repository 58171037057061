import React, { useEffect, useState } from "react";
import axios from "axios";
import { Spinner, Alert } from "reactstrap";
import { useHistory } from "react-router";

const GetQuoteInt = (props) => {
  const [apptoken, setapptoken] = useState(process.env.REACT_APP_APPTOKEN);
  const [endpoint, setendpoint] = useState(process.env.REACT_APP_ENDPOINT);

  // Sender
  const [country, setcountry] = useState("");
  const [weight, setweight] = useState("");
  const [express, setexpress] = useState("0");
  const [price_th, setprice_th] = useState("");
  const [price, setprice] = useState("");
  const [express_th, setexpress_th] = useState("");
  // Countries
  const [allcountries, setallcountries] = useState([]);
  const [count, setcount] = useState(0);

  const [issending, setissending] = useState(false);
  const [showalert, setshowalert] = useState(false);
  const [alert, setalert] = useState("");

  let history = useHistory();

  // Function for to process the form
  function SendLocalPackage(e) {
    if ((country, weight)) {
      setissending(true);
      const data = new FormData();
      data.append("country", country);
      data.append("weight", weight);
      // data.append("express", express);
      data.append("apptoken", apptoken);

      axios
        .post(`${endpoint}/v1/get-quote-int`, data, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((res) => {

          if (res.data.success === true) {
            setshowalert(true);
            setalert(res.data.message);
            setprice_th(res.data.price_th);
            setexpress_th(res.data.express_th);
            setprice(res.data.price);
            // setexpress(res.data.express);
            setissending(false);
          } else {
            setshowalert(true);
            setalert(res.data.message);
            setissending(false);
          }
        })
        .catch((error) => {

          setshowalert(true);
          setalert(` ${error.name}`);
          setissending(false);
        });
    } else {
      setshowalert(true);
      setalert("Empty fields");
    }
    e.preventDefault();
  }
  // Function for to process the form
  console.log(express)
  // Function for to call all countries
  const fetchcountries = () => {
    const data = {
      apptoken: apptoken,
    };
    axios
      .get(`${endpoint}/v1/get-countries`, { params: data })
      .then((response) => {
        if (response.data.success === false) {

        } else {
          setallcountries(response.data);

        }
      })
      .catch((error) => {

      });
  };
  useEffect(() => {
    fetchcountries();
  }, [count]);

  const countries = allcountries.map((item, i) => {
    return <option value={`${item.country}`}> {item.country} </option>;
  });
  // Function for to call all countries


  return (
    <>
      <div className="row send mb-0 pb-5 mt-2 justify-content-center">
        <div className="col-11 col-md-6 ">
          <div class="col-md-12">
            {showalert ? (
              <>
                <Alert color="success">{alert}</Alert>
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="form">
            <form>
              <div className="row">
                <h6 className="text-center text-white"> From: </h6>

                <div className="row justify-content-center">
                  <div className="col-md-12 ">
                    <select
                      className="input-style"
                    >
                      <option value="" >
                        Select Country *
                      </option>
                      <option value="" >
                        Nigeria
                      </option>

                    </select>
                  </div>

                  <div className="col-12">
                    <label className="text-center white-text">
                      To:
                    </label>
                  </div>
                  <div className="col-md-12 ">
                    <select
                      className="input-style"
                      onChange={(e) => setcountry(e.target.value)}
                    >
                      <option value="" >
                        Select Country *
                      </option>

                      {countries}
                    </select>
                  </div>

                  <div className="col-md-6 ml-auto mr-auto">
                    <div className="input-group">
                      <input
                        type="text"
                        className=" input-style"
                        placeholder="Enter Weight"
                        onChange={(e) => setweight(e.target.value)}
                        value={weight}
                      />
                    </div>
                  </div>


                  <div class="hide">
                    {showalert ? (
                      <>
                        <Alert color="success">{alert}</Alert>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div class="col-md-6 mx-auto text-center">
                    <div class="user-btn mr-auto text-center">
                      {issending ? (
                        <>
                          <button
                            type="button"
                            className="btn shadow waves-effect"
                            action="submit"
                          >
                            <strong>
                              <Spinner color="success" />
                            </strong>
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="button"
                            class="btn shadow waves-effect"
                            action="submit"
                            onClick={(e) => SendLocalPackage(e)}
                          >
                            <strong> submit </strong>
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="col-md-6">
          <div className="second mt-5 text-center white-text">
          <h6 className="mt-4"> Estimated Shipping Fee</h6>

            {express_th === "" ? (
              <>
                <h1 style={{ fontSize: "70px" }}>₦ 0.00 </h1>
              </>
            ) : (
              <>
                {express === "0" ? (
                  <>
                    <h1 style={{ fontSize: "60px" }}>₦ {express_th} </h1>
                  </>
                ) : (
                  <>
                    {price === "0" ? (
                      <>
                        <h5 style={{ fontSize: "20px" }}> Oh Sorry! Cargo Delivery is not available to this location.</h5>
                      </>
                    ) : (
                      <>
                        <h1 style={{ fontSize: "60px" }}>₦ {price_th} </h1>

                      </>
                    )}

                  </>
                )}
                <div className="col-md-6 mx-auto">
                  <select
                    className="input-style"
                    onChange={(e) => setexpress(e.target.value)}
                  >
                    <option value="0">
                      Shipment Type *
                    </option>
                    <option value="0">
                      Express Shipment
                    </option>
                    <option value="1">
                      Cargo Shipment
                    </option>

                  </select>
                </div>
              </>
            )}

{express === "0" ? <p>
Express: Delivered within 3-5 working days
</p> : <p> Cargo: Delivered within 10-15 working days</p>}
          </div>
        </div>
      </div>

      {/* <Footer /> */}
    </>
  );
};

export default GetQuoteInt;
