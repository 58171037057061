import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { Spinner, Alert } from "reactstrap";

const AdminViewWithdrawal = (props) => {
  const [apptoken, setapptoken] = useState(process.env.REACT_APP_APPTOKEN);
  const [endpoint, setendpoint] = useState(process.env.REACT_APP_ENDPOINT);

  const [load, setload] = useState(false);
  const [showalert, setshowalert] = useState(false);
  const [alertt, setalert] = useState("");


  const MarkPaid = () => {
    setload(true);
    const data = new FormData();
    data.append("wid", props.details.wid);
    data.append("apptoken", apptoken);

    axios
      .post(`${endpoint}/v1/admin-pay-withdrawal`, data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.success == false) {
          
          setload(false);
          setshowalert(true);
          setalert(res.data.message);
        } else {
          
          setload(false);
          setshowalert(true);
          alert(res.data.message);
          reload();
          setalert(res.data.message);
        }
      })
      .catch((error) => {
        
        setload(false);
        setshowalert(true);
        setalert("Check your Network Connection!!!");
      });
  };

  function reload() {
    window.location.reload();
  }
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton onClick={reload}></Modal.Header>
        <Modal.Body style={{ backgroundColor: "transparent!important" }}>
          <section class=" ">
            <div class="container">
              <div class="mb-5">
                <h4>
                  {" "}
                  Withdrawal Details

                </h4>
                <hr/>
                <h5 class="font-weight-light">Bank Name: <br/> {props.details.bank}</h5>
                <h5 class="font-weight-light">Account Name: <br/> {props.details.name}</h5>
                <h5 class="font-weight-light">Account Number: <br/> {props.details.aza}</h5>
              </div>

              <div class="text-center h5">
                {showalert ? (
                  <>
                    <Alert color="success">{alertt}</Alert>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </section>
        </Modal.Body>
        <Modal.Footer>
          <div class="ml-auto mr-auto">
            {load ? (
              <>
                <button type="button" class="btn btn-success  btn my-0" disabled>
                  loading <Spinner color="light" />
                </button>
              </>
            ) : (
              <>
                <button
                  type="button"
                  class="btn btn-success  btn my-0"
                  onClick={(e) => MarkPaid(e)}
                >
                  Mark as Paid
                </button>
              </>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminViewWithdrawal;
