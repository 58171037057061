import React, { useEffect, useState } from "react";
import axios from "axios";
import { Spinner } from "reactstrap";
import AdminNavbar from "../../../Navbar/AdminNavbar";
import img from "../img/icons.png";
import AdminViewWithdrawal from "./AdminViewWithdrawal";

const AdminListWithdraw = () => {
  const [apptoken, setapptoken] = useState(process.env.REACT_APP_APPTOKEN);
  const [endpoint, setendpoint] = useState(process.env.REACT_APP_ENDPOINT);

  const [count, setcount] = useState(0);
  const [load, setload] = useState(false);

  const [allwithdrawals, setallwithdrawals] = useState([]);

  // For the Delete Button
  const [showremove, setShowremove] = useState(false);
  const handleCloseremove = () => setShowremove(false);
  const handleShowremove = () => setShowremove(true);
  const [details, setdetails] = useState("");

  function workModal(token) {
    setdetails(token);
    handleShowremove();
    console.log(token)
  }

  const fetchwithdrawals = () => {
    setload(true);
    const data = {
      apptoken: apptoken,
    };
    axios
      .get(`${endpoint}/v1/admin-list-pending-withdrawal`, {
        params: data,
      })
      .then((response) => {
        if (response.data.success == false) {
          setload(false);
        } else {
          setallwithdrawals(response.data);

          setload(false);
        }
      })

      .catch((error) => {
        setload(false);
      });
  };
  useEffect(() => {
    fetchwithdrawals();
  }, [count]);

  const withdrawals = allwithdrawals.map((item, i) => {
    return (
      <tr key={i}>
        <td className="pt-3-half">{item.wid}</td>
        <td className="pt-3-half">{item.timeago}</td>
        <td className="pt-3-half">{item.usertoken}</td>
        {/* <td className="pt-3-half">{item.type}</td> */}
        <td className="pt-3-half">
          {" "}
          <span className="font-weight-bold red-text">
            ₦ ( {item.amount_th} )
          </span>
        </td>
        <td className="pt-3-half">{item.bank_name}</td>
        <td className="pt-3-half">{item.account_name}</td>
        <td className="pt-3-half">{item.account_no}</td>
        <td>
          <span className="table-remove">
            <button
              type="button"
              onClick={(e) => workModal({wid:item.wid, bank:item.bank_name, name:item.account_name, aza:item.account_no})}
              className="btn btn-success btn-rounded btn-sm my-0"
            >
              View
            </button>
          </span>
        </td>{" "}
      </tr>
    );
  });

  return (
    <>
      <AdminNavbar />
      <section className="transactionslist">
        <div className="mt-3 container-fluid">
          <div className="card">
            <h5 className="card-header text-center font-weight-bold text-uppercase py-4">
              ALL withdrawals
            </h5>
            <div className="card-body">
              <div id="table" className="table-editable">
                <span className="table-add float-right mb-3 mr-2">
                  <a href="#!" className="text-success">
                    <i className="fas fa-plus fa-2x" aria-hidden="true"></i>
                  </a>
                </span>
                <table className="table table-bordered table-responsive-sm table-striped text-center">
                  <thead>
                    <tr>
                      <th className="text-center">ID</th>
                      <th className="text-center">Date</th>
                      <th className="text-center">User Token</th>
                      <th className="text-center">Amount(to Pay)</th>
                      <th className="text-center">Bank Name</th>
                      <th className="text-center">Account Name</th>
                      <th className="text-center">Account Number</th>
                      <th className="text-center">View </th>
                    </tr>
                  </thead>
                  {load ? (
                    <div className="my-5 justify-content-center">
                      <Spinner color="dark" /> Loading Withdrawals
                    </div>
                  ) : (
                    <>
                      {withdrawals == "" ? (
                        <>
                          <div className="mt-5 text-center ">
                            <img src={img} width="100px" />
                            <h5
                              class="mt-3 font-weight-normal mb-5"
                              style={{ color: "#CCCCCC" }}
                            >
                              Withdrawal is empty ...
                            </h5>
                          </div>
                        </>
                      ) : (
                        <>
                          <tbody>{withdrawals}</tbody>
                        </>
                      )}
                    </>
                  )}
                </table>
              </div>
            </div>
          </div>
          <AdminViewWithdrawal callback={fetchwithdrawals} show={showremove} onHide={handleCloseremove} animation={false} details={details}/>
z
        </div>
      </section>
    </>
  );
};

export default AdminListWithdraw;
