import React, { useState, useEffect } from "react";
import axios from "axios";
import { Spinner, Alert } from "reactstrap";
import AdminNavbar from "../../Navbar/AdminNavbar";
import { useHistory } from "react-router-dom";

const EditStates = (props, state) => {
  const [apptoken, setapptoken] = useState(process.env.REACT_APP_APPTOKEN);
  const [endpoint, setendpoint] = useState(process.env.REACT_APP_ENDPOINT);

  const [statename, setstatename] = useState(props.location.state.state);
  const [stateid, setstateid] = useState(props.location.state.id);
  const [pickup, setpickup] = useState("");
  const [status, setstatus] = useState(props.location.state.status);
  const [price, setprice] = useState(props.location.state.price);
  const [price_kg, setprice_kg] = useState(props.location.state.p_kg);
  const [price_express, setprice_express] = useState(
    props.location.state.p_ex);

  const [issending, setissending] = useState(false);
  const [showalert, setshowalert] = useState(false);
  const [alertt, setalert] = useState("");

  let history = useHistory();

  function StatesEdit(e) {
    if ((statename, stateid, price, price_kg, price_express)) {
      setissending(true);

      const data = new FormData();
      data.append("statename", statename);
      data.append("stateid", stateid);
      data.append("pickup", pickup);
      data.append("price", Number(price));
      data.append("price_kg", Number(price_kg));
      data.append("price_express", Number(price_express));
      data.append("apptoken", apptoken);

      axios
        .post(`${endpoint}/v1/admin-edit-state`, data, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data.success === true) {
            setshowalert(true);
            setalert(res.data.message);
            setpickup(res.data.pickup);
            setissending(false);
            // window.location.reload(true);
            history.push("/admin/states");
            alert(res.data.message);
          } else {
            setshowalert(true);
            setalert(res.data.message, "error");
            setissending(false);
          }
        })
        .catch((error) => {
          setshowalert(true);
          setalert("Check your Network Connection!!!");
          setissending(false);
        });
    } else {
      setshowalert(true);
      setalert("Empty fields, Check form again!");
      setissending(false);
    }
    e.preventDefault();
  }

  return (
    <>
      <AdminNavbar />
      <section className="add-food">
        <div className="container">
          <div className="send">
            <div className="text-center">
              <h5>
                {" "}
                EDIT STATE - <span className="green-text">
                  {statename}
                </span>{" "}x
              </h5>
            </div>
            <hr />
            <div className="form">
              <form>
                <div className="row justify-content-center">
                  <div className="col-md-10 ">
                    <label> State: </label>

                    <div className="input-group">
                      <input
                        type="text"
                        className=" input-style"
                        placeholder="State *"
                        onChange={(e) => setstatename(e.target.value)}
                        value={statename}
                      />
                    </div>
                  </div>

                  <div className="col-md-10 ">
                    <label>
                      {" "}
                      The price per kg for any package higher than 2kg:{" "}
                    </label>

                    <div className="input-group">
                      <input
                        type="number"
                        className="input-style"
                        placeholder="Price per kg above 2kg *"
                        onChange={(e) => setprice_kg(e.target.value)}
                        value={price_kg}
                      />
                    </div>
                  </div>

                  <div className="col-md-10 ">
                    <label> The price to send package to {statename}: </label>

                    <div className="input-group">
                      <input
                        type="number"
                        className="input-style"
                        placeholder="Price"
                        onChange={(e) => setprice(e.target.value)}
                        value={price}
                      />
                    </div>
                  </div>

                  <div className="col-md-10 ">
                    <label> The price for express delivery: </label>

                    <div className="input-group">
                      <input
                        type="number"
                        className="input-style"
                        placeholder="Price *"
                        onChange={(e) => setprice_express(e.target.value)}
                        value={price_express}
                      />
                    </div>
                  </div>

                  <div className="col-md-10 ">
                    <label> Pickup:</label>
                    <Alert color="dark">{status}</Alert>

                    <div className="input-group">
                       <select
                      className="input-style"
                      onChange={(e) => setpickup(e.target.value)}
                    >
                      <option value="">Select Pickup Status *</option>
                      <option value="1">ENABLE</option>
                      <option value="0">DISABLE</option>

                    </select>
                    </div>
                  </div>
                  <div class="col-md-10  mx-auto text-center">
                    {showalert ? (
                      <>
                        <Alert color="success">{alertt}</Alert>
                      </>
                    ) : (
                      <></>
                    )}
                    <div class="user-btn mb-4 mr-auto text-center">
                      {issending ? (
                        <>
                          <button
                            type="button"
                            class="btn btn-dark shadow waves-effect"
                            action="submit"
                          >
                            <strong>
                              editing <Spinner color="light" />
                            </strong>
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="button"
                            class="btn btn-dark shadow waves-effect"
                            action="submit"
                            onClick={(e) => StatesEdit(e)}
                          >
                            <strong> edit </strong>
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <hr />
          </div>
        </div>
      </section>
    </>
  );
};

export default EditStates;
