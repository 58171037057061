import React, { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import { usePaystackPayment } from "react-paystack";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { Spinner, Alert } from "reactstrap";

const FundWallet = () => {
  const [apptoken, setapptoken] = useState(process.env.REACT_APP_APPTOKEN);
  const [endpoint, setendpoint] = useState(process.env.REACT_APP_ENDPOINT);
  const [publickey, setpublickey] = useState(
    process.env.REACT_APP_PAYSTACK_PUBLICKEY
  );
  const [public_key, setpublic_key] = useState(
    process.env.REACT_APP_FLUTTERWAVE_PUBLICKEY
  );

  const [load, setload] = useState(false);

  const [usertoken, setusertoken] = useState("");
  const [amount, setamount] = useState("");
  const [email, setemail] = useState(localStorage.getItem("eclemail"));
  const [phone, setphone] = useState(localStorage.getItem("eclphone"));
  const [name, setname] = useState(localStorage.getItem("eclfullname"));
  const [alertt, setalert] = useState("");
  const [showalert, setshowalert] = useState(false);
  let history = useHistory();

  function reload() {
    window.location.reload();
  }
  
  // Addition of 1.5% to Paystack amount
  let price= amount;
  let pricepercent= "";
  let actualprice= "";
  pricepercent= Number(price) * 0.015;
  actualprice= Number(pricepercent) + Number(amount);
  // Addition of 1.5% to Paystack amount


  // PAYSTACK PAYMENT
  // PAYSTACK PAYMENT
  const config = {
    reference: new Date().getTime().toString(),
    email: email,
    amount: Number(actualprice) * 100,
    publicKey: publickey,
  };

  // you can call this function anything
  const onSuccess = (reference) => {
    PaystackPayment();
    console.log(reference);
  };
  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
    reload();
  };

  const PaystackHookExample = () => {
    const initializePayment = usePaystackPayment(config);
    return (
      <div>
        <button
          class="btn btn-green"
          onClick={() => {
            initializePayment(onSuccess, onClose);
          }}
        >
          Paystack{" "}
        </button>
      </div>
    );
  };
  // PAYSTACK PAYMENT
  // PAYSTACK PAYMENT

  // FLUTTERWAVE PAYMENT
  // FLUTTERWAVE PAYMENT
  const configflutter = {
    public_key: public_key,
    tx_ref: Date.now(),
    amount: Number(amount),
    currency: "NGN",
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email: email,
      phonenumber: phone,
      name: name,
    },
    customizations: {
      title: "Fund Wallet",
      description: "Payment for funding wallet",
      logo: "https://eclipse.com.ng/logo.jpeg"
    },
  };

  const handleFlutterPayment = useFlutterwave(configflutter);

  const FlutterHookBtn = () => {
    return (
      <button
        class="btn btn-yellow"
        onClick={() => {
          handleFlutterPayment({
            callback: (response) => {
              console.log(response);
              PaystackPayment();
              closePaymentModal(); // this will close the modal programmatically
            },
            onClose: () => {
              reload();
            },
          });
        }}
      >
        FLUTTERWAVE
      </button>
    );
  };

  // FLUTTERWAVE PAYMENT
  // FLUTTERWAVE PAYMENT

  // ONSUCCESS CALL THIS
  const PaystackPayment = () => {
    if ((usertoken, amount)) {
      setload(true);

      const data = new FormData();
      data.append("usertoken", localStorage.getItem("eclusertoken"));
      data.append("amount", amount);
      data.append("apptoken", apptoken);

      axios
        .post(`${endpoint}/v1/fund-wallet`, data, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data.success === true) {
            setload(false);
            history.push("/dashboard");
            setshowalert(true);
            window.location.reload(true);
            alert(res.data.message);
          } else {
            setload(false);
          }
        })
        .catch((error) => {
          setload(false);
        });
    } else {
      setshowalert(true);
      setload(false);
    }
  };
  // ONSUCCESS CALL THIS
  return (
    <>
      <section className="fundwallet">
        <div
          className="col-md-10 py-4 white ml-auto mr-auto shadow"
          style={{ borderRadius: "1rem" }}
        >
          <div className="container">
            <h5 className="text-center "> FUND WALLET</h5>

            {load ? (
              <div className="text-center">
                <Spinner color="success" />
                <h5 className="font-weight-light">
                  Please wait for your transaction is processing{" "}
                </h5>
                <h6> Do not refresh page!!! </h6>
              </div>
            ) : (
              <>
                <div className="form">
                  {showalert ? (
                    <>
                      <Alert color="success">{alertt}</Alert>
                    </>
                  ) : (
                    <></>
                  )}

                  <form>
                    <div className="row justify-content-center">
                      <div className="col-md-6 text-center">
                        <label> Amount </label>

                        <div className="input-group">
                          <input
                            required
                            type="number"
                            className=" input-style"
                            placeholder="Enter Amount"
                            onChange={(e) => setamount(e.target.value)}
                            value={amount}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                {amount === "" ? (
                  <div class="row justify-content-center">
                    <div className="text-center">
                      <button class="btn btn-green" disabled>
                        Paystack
                      </button>
                      OR
                    </div>
                     
                    <div className="text-center">
                      <button class="btn btn-yellow" disabled>
                        flutterwave
                      </button>{" "}
                    </div>
                  </div>
                ) : (
                  <div class="row justify-content-center">
                    <div className="text-center">
                      <PaystackHookExample />

                    </div> 
                    <div className="text-center">
                     OR <FlutterHookBtn />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default FundWallet;
