import React from "react";
import AllOrder from "../../Components/Admin/Order/AllOrder";
import DeliveredOrder from "../../Components/Admin/Order/DeliveredOrder";
import PaidOrder from "../../Components/Admin/Order/PaidOrder";
import UnpaidOrder from "../../Components/Admin/Order/UnpaidOrder";
import AdminNavbar from '../../Components/Navbar/AdminNavbar'

const AdminAllOrders = () => {
  return (
    <>
    <AdminNavbar />
      <div className="send-package first pt-5">
        <div className="mt-5 text-center">
          {/* <h5> Transaction History </h5> */}
        </div>
        <div className="trans-history">
          <div className="nav-tab">
        <ul
          class="nav nav-tabs justify-content-center "
          id="myTab"
          role="tablist"
        >
          <li class="nav-item">
            <a
              class="nav-link active"
              id="home-tab"
              data-toggle="tab"
              href="#all-orders"
              role="tab"
              aria-controls="home"
              aria-selected="true"
            >
              All Orders
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="home-tab"
              data-toggle="tab"
              href="#paid-orders"
              role="tab"
              aria-controls="home"
              aria-selected="true"
            >
              Paid Orders
            </a>
          </li>
           <li class="nav-item">
            <a
              class="nav-link"
              id="home-tab"
              data-toggle="tab"
              href="#unpaid-orders"
              role="tab"
              aria-controls="home"
              aria-selected="true"
            >
              Unpaid Orders
            </a>
          </li>
            <li class="nav-item">
            <a
              class="nav-link"
              id="home-tab"
              data-toggle="tab"
              href="#delivered-orders"
              role="tab"
              aria-controls="home"
              aria-selected="true"
            >
              Deliverd Orders
            </a>
          </li>
     
        </ul>
        </div>
        <div class="tab-content" id="myTabContent">
          <div
            class="tab-pane fade show active"
            id="all-orders"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <AllOrder />
          </div>
          <div
            class="tab-pane fade"
            id="paid-orders"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <PaidOrder />
          </div>
          <div
            class="tab-pane fade"
            id="unpaid-orders"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <UnpaidOrder />
          </div>
          <div
            class="tab-pane fade"
            id="delivered-orders"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <DeliveredOrder />
          </div>
        </div>
        </div>
      </div>
    </>
  );
};

export default AdminAllOrders;
