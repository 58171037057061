import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";
import DelZone from "./DelZone";
import { useHistory } from "react-router-dom";

const AllZones = () => {
  const [apptoken, setapptoken] = useState(process.env.REACT_APP_APPTOKEN);
  const [endpoint, setendpoint] = useState(process.env.REACT_APP_ENDPOINT);

  const [count, setcount] = useState(0);
  const [load, setload] = useState(false);

  // For the Delete Button
  const [showremove, setShowremove] = useState(false);
  const handleCloseremove = () => setShowremove(false);
  const handleShowremove = () => setShowremove(true);
  const [zoneid, setzoneid] = useState("");

  function workModal(token) {
    
    setzoneid(token);
    handleShowremove();
  }

  let history = useHistory();

  const [allzones, setallzones] = useState([]);

  const fetchzones = () => {
    setload(true);
    const data = {
      apptoken: apptoken,
    };
    axios
      .get(`${endpoint}/v1/admin-list-zones`, {
        params: data,
      })
      .then((response) => {
        if (response.data.success == false) {
          
          setload(false);
        } else {
          setallzones(response.data);
          
          setload(false);
        }
      })

      .catch((error) => {
        
        setload(false);
      });
  };
  useEffect(() => {
    fetchzones();
  }, [count]);

  const zones = allzones.map((item, i) => {
    return (
      <tr key={i}>
        <td className="pt-3-half">{item.id}</td>
        <td className="pt-3-half">{item.name}</td>
        <td className="pt-3-half">{item.details}</td>
        <td className="pt-3-half">₦ {item.price}</td>
        <td className="pt-3-half">₦ {item.price_kg}</td>
        <td className="pt-3-half">₦ {item.price_cargo}</td>
        <td className="pt-3-half">₦ {item.price_cargo_kg}</td>
        <td className="pt-3-half">{item.timestamp}</td>
        <td className="pt-3-half">
          <span className="table-up">
            {/* <Link to={`/admin/edit-zone/${item.id}`}> */}
              <button
                type="button"
                className="btn btn-success btn-rounded btn-sm my-0"
              onClick={(e) => dozone(item.id, item.name, item.details, item.price,
                 item.price_kg, item.price_cargo, item.price_cargo_kg,)}

              >
                Edit
              </button>
            {/* </Link>{" "} */}
          </span>
        </td>
        <td>
          <span className="table-remove">
            <button
              type="button"
              onClick={(e) => workModal(item.id)}
              className="btn btn-danger btn-rounded btn-sm my-0"
            >
              Remove
            </button>
          </span>
        </td>
      </tr>
    );
  });

  const dozone = (id, name, details, price, price_kg, price_cargo, price_cargo_kg) => {
    const obj = {id, name, details, price, price_kg, price_cargo, price_cargo_kg };

    history.push({
      pathname: `/admin/edit-zone/${id}`,
      state: obj,
    });
  };

  return (
    <>
      <div className="adminzones">
        <div className="mt-3">
          <div className="card">
            <h5 className="card-header text-center font-weight-bold text-uppercase py-4">
              ALL zones
            </h5>
            <div className="card-body">
              <div id="table" className="table-editable">
                <span className="table-add float-right mb-3 mr-2">
                  <a href="#!" className="text-success">
                    <i className="fas fa-plus fa-2x" aria-hidden="true"></i>
                  </a>
                </span>
                <table className="table table-bordered table-responsive-md table-striped text-center">
                  <thead>
                    <tr>
                      <th className="text-center">ID</th>
                      <th className="text-center">Zone</th>
                      <th className="text-center">Details</th>
                      <th className="text-center">Price(₦)</th>
                      <th className="text-center">Price Per Kg(₦)</th>
                      <th className="text-center">Cargo Price(₦)</th>
                      <th className="text-center">Cargo Price Per Kg(₦)</th>
                      <th className="text-center">Time Created</th>
                      <th className="text-center">Edit</th>
                      <th className="text-center">Remove</th>
                    </tr>
                  </thead>
                  <tbody>
                    {load ? (
                      <div className="my-5 justify-content-center">
                        <Spinner color="dark" /> Loading zoness
                      </div>
                    ) : (
                      <>{zones}</>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <DelZone
            callback={fetchzones}
            show={showremove}
            onHide={handleCloseremove}
            animation={false}
            zoneid={zoneid}
          />
        </div>
      </div>
    </>
  );
};

export default AllZones;
