import React, { useState, useEffect } from "react";
import axios from "axios";
import { Spinner, Alert } from "reactstrap";
import { useHistory, useParams } from "react-router";
import Navbar from "../../Navbar/Navbar";
import icon from "./img/icon.svg";
import img from "./img/icons1.png";

const Track = () => {
  const [apptoken, setapptoken] = useState(process.env.REACT_APP_APPTOKEN);
  const [endpoint, setendpoint] = useState(process.env.REACT_APP_ENDPOINT);
  const [count, setcount] = useState(0);
  const [trackid, settrackid] = useState("");
  const [results, setresults] = useState([]);

  let { orderid } = useParams();

  const [issending, setissending] = useState(false);
  const [showalert, setshowalert] = useState(false);
  const [alert, setalert] = useState("");
  let history = useHistory();

  useEffect(() => {
    if (orderid) {
      settrackid(orderid);

      autoTrackings(orderid);
    }
  }, [count]);

  function autoTrackings(trackid) {
    if (orderid) {
      setissending(true);

      const data = new FormData();
      data.append("trackid", trackid);
      data.append("apptoken", apptoken);

      axios
        .post(`${endpoint}/v1/track-order`, data, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data.success === false) {
            setissending(false);
            setshowalert(true);
            setalert(res.data.message);
          } else {
            localStorage.setItem("trackid", trackid);
            setresults(res.data);
            setshowalert(false);
            setissending(false);
          }
        })
        .catch((error) => {
          setshowalert(true);
          setalert("Check your Network Connection!!!");
          setissending(false);
        });
    } else {
      setshowalert(true);
      setalert("Tracking ID not found!");
      setissending(false);
    }
    // e.preventDefault();
  }

  // Track Result
  const trackdetails = results
    .map((item) => {
      return (
        <li>
          <div className="eachorder">
            <img src={icon} width="70px" className="img" />
            <div className="first col-md-10">
              <h6 class="">{item.log} </h6>
              <p class="grey-text smaller-text">{item.timestamp} </p>
              <hr className="p-0 m-0" />
              <p class="smaller-text">{item.sender} </p>
            </div>
          </div>
        </li>
      );
    })
    .reverse();

  return (
    <>
      <Navbar />

      <section className="col-md-8 tracking ml-auto mr-auto send-package">

        <div className="pt-5">
          <div className="mt-4 text-center green-text">
            <h3> TRACKING DETAILS</h3>
            <hr />
          </div>
          {trackdetails == "" ? (
            <>
              <div className="mt-5 text-center">
                <img src={img} width="100px" />
                <h5
                  class="mt-3 font-weight-normal"
                  style={{ color: "#CCCCCC" }}
                >
                  {" "}
                  Track ID is empty ...
                </h5>
              </div>
            </>
          ) : (
            <>
              <div className="track">
                <div className="container">
                  {issending ? (
                    <Spinner color="success" />
                  ) : (
                    <>
                      <div className="mb-4">
                        <h6 className="ml-2">
                          Tracking Number -
                          <span className="h5 font-weight-bold">
                            {trackid}{" "}
                          </span>
                        </h6>
                      </div>

                      <div className="">
                        <ol>{trackdetails}</ol>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default Track;
