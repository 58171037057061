import React, { useState, useEffect } from "react";
import axios from "axios";
import { Spinner, Alert } from "reactstrap";
import AdminNavbar from "../../Navbar/AdminNavbar";
import { useHistory } from "react-router-dom";

const EditZones = (props) => {
  const apptoken = process.env.REACT_APP_APPTOKEN
  const endpoint = process.env.REACT_APP_ENDPOINT

// console.log(props.location.state)

  const [zonename, setzonename] = useState(props.location.state.name);
  const [zoneid, setzoneid] = useState(props.location.state.id);
  const [price, setprice] = useState(props.location.state.price);
  const [price_kg, setprice_kg] = useState(props.location.state.price_kg);
  const [cargo, setcargo] = useState(props.location.state.price_cargo);
  const [cargoKg, setCargoKg] = useState(props.location.state.price_cargo_kg);
  const [description, setdescription] = useState(props.location.state.details);

  let history = useHistory();

  const [issending, setissending] = useState(false);
  const [showalert, setshowalert] = useState(false);
  const [alertt, setalert] = useState("");

  function ZonesEdit(e) {
    // console.log((zonename, zoneid, price, price_kg, description, cargo, cargoKg))
    if (zonename && zoneid && price && price_kg && description && cargo && cargoKg) {
      setissending(true);

      const data = new FormData();
      data.append("details", description);
      data.append("zoneid", zoneid);
      data.append("price", Number(price));
      data.append("price_kg", price_kg);
      data.append("price_cargo", cargo);
      data.append("price_cargo_kg", cargoKg);
      data.append("description", 0);
      data.append("apptoken", apptoken);

      axios
        .post(`${endpoint}/v1/admin-edit-zone`, data, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data.success === true) {
            setshowalert(true);
            setalert(res.data.message);
            setissending(false);
            // window.location.reload(true);
            history.push('/admin/countries')
            alert(res.data.message)
            
          } else {
            setshowalert(true);
            setalert(res.data.message, "error");
            setissending(false);
            
          }
        })
        .catch((error) => {
          
          setshowalert(true);
          setalert("Check your Network Connection!!!");
          setissending(false);
        });
    }
else {
      setshowalert(true);
      setalert("Empty fields, Check form again!");
      setissending(false);
    }
    e.preventDefault();
  }
  

  return (
    <>
      <AdminNavbar />
      <section className="add-food">
        <div className="container">
          <div className="send">
            <div className="text-center">
              <h5>
                {" "}
                EDIT ZONE - <span className="green-text">{zonename}</span>{" "}
              </h5>
            </div>
            <hr />
            <div className="form">
              <form>
                <div className="row justify-content-center">
              
                  <div className="col-md-10 ">
                    <label> Details: </label>

                    <div className="input-group">
                      <input
                        type="text"
                        className="input-style"
                        placeholder="Zone Details *"
                        onChange={(e) => setdescription(e.target.value)}
                        value={description}
                      />
                    </div>
                  </div>

                  <div className="col-md-10 ">
                    <label> The price to send package to {zonename}: </label>

                    <div className="input-group">
                      <input
                        type="number"
                        className="input-style"
                        placeholder="Price"
                        onChange={(e) => setprice(e.target.value)}
                        value={price}
                      />
                    </div>
                  </div>

                  <div className="col-md-10 ">
                    <label> KG: </label>

                    <div className="input-group">
                      <input
                        type="text"
                        className="input-style"
                        placeholder="KG *"
                        onChange={(e) => setprice_kg(e.target.value)}
                        value={price_kg}
                      />
                    </div>
                  </div>

                  <div className="col-md-10 ">
                    <label> Cargo Price: </label>

                    <div className="input-group">
                  
                        <input
                        type="number"
                        className="input-style"
                        placeholder="Cargo Price *"
                        onChange={(e) => setcargo(e.target.value)}
                        value={cargo}
                      />
                      
                    </div>
                  </div>

                  <div className="col-md-10 ">
                    <label> Cargo Kg Price: </label>

                    <div className="input-group">
                  
                        <input
                        type="number"
                        className="input-style"
                        placeholder="Cargo Kg Price *"
                        onChange={(e) => setCargoKg(e.target.value)}
                        value={cargoKg}
                      />
                      
                    </div>
                  </div>
                  <div class="col-md-10  mx-auto text-center">
                    {showalert ? (
                      <>
                        <Alert color="success">{alertt}</Alert>
                      </>
                    ) : (
                      <></>
                    )}
                    <div class="user-btn mb-4 mr-auto text-center">
                      {issending ? (
                        <>
                          <button
                            type="button"
                            class="btn btn-dark shadow waves-effect"
                            action="submit"
                          >
                            <strong>
                              editing <Spinner color="light" />
                            </strong>
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="button"
                            class="btn btn-dark shadow waves-effect"
                            action="submit"
                            onClick={(e) => ZonesEdit(e)}
                          >
                            <strong> EDIT </strong>
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <hr />
          </div>
        </div>
      </section>
    </>
  );
};

export default EditZones;
