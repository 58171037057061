import React from 'react';


const FoodShowcase = () => {
    return (
        <>
 <section class="sendshowcase showcase ">
        <div class="container ">
          <div class="showcase-content ">
            <div class=" animated fadeInUp content col-md-8 ml-auto mr-auto pt-5">
              <h1 class="text-center ">
                {" "}
               BUY FOODS
              </h1>
            </div>

          </div>

        </div>
      </section>

        </>
    );
}
 
export default FoodShowcase;