import React, { useEffect, useState } from "react";
import { Alert } from "reactstrap";
import PayWithPayStack from "./PayWithPayStack";
import PayWithWallet from "./PayWithWallet";

const RegularPay = (props) => {
  // pay with wallet modal
  const [showremove, setShowremove] = useState(false);
  const handleCloseremove = () => setShowremove(false);
  const handleShowremove = () => setShowremove(true);
  const [trackingid, settrackingid] = useState("");

  function workModal(token) {
    settrackingid(token);
    handleShowremove();
  }

  // pay with paystack modal
  const [showremove1, setShowremove1] = useState(false);
  const handleCloseremove1 = () => setShowremove1(false);
  const handleShowremove1 = () => setShowremove1(true);

  function workModal1(token) {
    settrackingid(token);
    handleShowremove1();
  }

  return (
    <>
      <div className="card-body text-center">
        <h4 className="mb-4">
          <span className="bolder-text h6">Amount: </span>
        </h4>
        <h1 className="bolder-text">₦ {props.priceth}</h1>
      </div>
      {props.priceth === "0" ? (
        <div className="mx-3 text-center">
          <Alert color="success">
            Sorry!, Regular Payment is not avialable for this order.
          </Alert>
        </div>
      ) : (
        <>
          {props.status === "paid" ? (
            <>
              <div className="mx-3 text-center">
                <Alert color="success">Payment Successful</Alert>
              </div>
            </>
          ) : (
            <>
              <div className="text-center invoice-btn">
                <div className="col-md-10 col-10 mx-auto text-center">
                  <Alert color="warning">
                    {props.type == "int" ? (
                      <small>NOTE: 3-5 Working Days Delivery.</small>
                    ) : (
                      <>
                        {props.sendertown === props.destination_town ? (
                          <small>NOTE: Within a Day Delivery.</small>
                        ) : (
                          <small>NOTE: 2-4 Working Days Delivery.</small>
                        )}
                      </>
                    )}
                  </Alert>
                </div>
                <button
                  class="btn w-75"
                  onClick={(e) => workModal1(props.trackid)}
                >
                  {" "}
                  pay with paystack/flutter
                </button>
                <button
                  class="btn w-75"
                  onClick={(e) => workModal(props.trackid)}
                >
                  {" "}
                  pay with wallet
                </button>
              </div>
            </>
          )}
        </>
      )}

      {/* Pay With Wallet Modal */}
      <PayWithPayStack
        show={showremove1}
        onHide={handleCloseremove1}
        animation={false}
        trackid={trackingid}
        amount={props.price}
        amountth={props.priceth}
        type={props.type}
      />
      {/* Pay With Wallet Modal */}

      {/* Pay With Wallet Modal */}
      <PayWithWallet
        show={showremove}
        onHide={handleCloseremove}
        animation={false}
        trackid={trackingid}
        amount={props.price}
        amountth={props.priceth}
        type={props.type}
      />
      {/* Pay With Wallet Modal */}
    </>
  );
};

export default RegularPay;
