import React from 'react';
import RegularPay from './RegularPay';
import { useState } from 'react';
import ExpressPay from './ExpressPay';

const Payment = (props) => {

  return ( <>
  <div className="payment">
  <ul
            class="nav nav-tabs justify-content-center "
            id="myTab"
            role="tablist"
          >
            <li class="nav-item">
              <a
                // onClick={LocalOrder}
                class="nav-link active"
                id="home-tab"
                data-toggle="tab"
                href="#regular"
                role="tab"
                aria-controls="home"
                aria-selected="true"
                // value="local" onClick={e => settype(e.target.value)}
              >
                {props.type == "int" ?  <>Express</> : <>Regular</>}
              </a>

            </li>
            
            <li class="nav-item">
              <a
                // onClick={IntOrder}
                class="nav-link"
                id="profile-tab"
                data-toggle="tab"
                href="#express"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
              {props.type == "int" ?  <>Cargo</> : <>Express</>}

              </a>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade show active"
              id="regular"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
                 
              <RegularPay trackid={props.trackid} price={props.price} priceth={props.priceth} type={props.type}  status={props.status} express_type={props.express_type} sendertown={props.sendertown} destination_town={props.destination_town} />
            </div>
            <div
              class="tab-pane fade"
              id="express"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <ExpressPay trackid={props.trackid} express={props.express} expressth={props.expressth} type={props.type} status={props.status}  express_type={props.express_type} />

            </div>
          </div>
          </div>
  </> );
}
 
export default Payment;