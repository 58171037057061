import React from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
const closeNav = (e) => {
  document.getElementById("mySidenav").style.width = "0";
};

const UserNavLinks = () => {
  let history = useHistory();

  const HandleLogout = () => {
    history.push("/home");
    localStorage.removeItem("ecladmintoken");
    localStorage.removeItem("ecladminname");
    localStorage.removeItem("ecladminmail");
    localStorage.removeItem("eclusertoken");
    localStorage.removeItem("eclfullname");
    localStorage.removeItem("eclemail");
    localStorage.removeItem("eclrefcode");
    localStorage.removeItem("eclphone");
    localStorage.removeItem("results");
    localStorage.removeItem("trackid");
  };

  function RemoveTrack() {
    localStorage.removeItem("results");
    localStorage.removeItem("trackid");
  }

  let navLink = [
    { title: "Dashboard", to: "dashboard", icon: "home", type:"logo"},
    { title: "Send Package", to: "send-package", icon: "gift", type:"logo"},
    { title: "Track", to: "track", icon: "paper-plane", type:"logo"},
    { title: "Get Quote", to: "get-quote", icon: "badge-dollar", type:"solid"},
    { title: "Profile", to: "profile", icon: "user", type:"logo"},
  ];

  return (
    <>
      {" "}
      <div class=" my-2">
        <Link to="/dashboard" className="navbar-brand">
          <h5 class="name text-center"> ECLIPSE </h5>
        </Link>
      </div>
      <hr style={{ backgroundColor: "whitesmoke" }} class="m-1" />
      <ul class="navbar-nav ml-auto mr-auto mt-3">
        {navLink.map(({ title, to, icon, type }, i) => {
          return (
            <li class="nav-item" key={i}>
              <box-icon
                class="box-icon"
                size="1.1rem"
                color=" #fff"
                name={icon}
                type={type}
              />
              <NavLink
                tag={Link}
                activeClassName="active1 "
                to={`/${to}`}
                onClick={RemoveTrack}
              >
                {title}
              </NavLink>
            </li>
          );
        })}

      </ul>
      <ul class="navbar-nav text-center">
        <li>
          <a onClick={HandleLogout}>
            <button type="btn" class="btn shadow ">
              logout
            </button>
          </a>
        </li>
      </ul>
    </>
  );
};

export default UserNavLinks;
