import React from 'react';
import Navbar from '../../Components/Navbar/Navbar'
import GetQuote from '../../Components/HomePage/GetQuote'


const GetQuotePage = () => {
    return ( 
        <>
        <Navbar />
        <GetQuote />
        </>
     );
}
 
export default GetQuotePage;