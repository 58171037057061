import React from "react";
import { useState } from "react";
import { Alert } from "reactstrap";

const AdPayment = (props) => {
  return (
    <>
      <div className="payment">
        <ul
          class="nav nav-tabs justify-content-center "
          id="myTab"
          role="tablist"
        >
          <li class="nav-item">
            <a
              // onClick={LocalOrder}
              class="nav-link active"
              id="home-tab"
              data-toggle="tab"
              href="#regular"
              role="tab"
              aria-controls="home"
              aria-selected="true"
              // value="local" onClick={e => settype(e.target.value)}
            >
              {props.type == "int" ? <>Express</> : <>Regular</>}
            </a>
          </li>

          <li class="nav-item">
            <a
              // onClick={IntOrder}
              class="nav-link"
              id="profile-tab"
              data-toggle="tab"
              href="#express"
              role="tab"
              aria-controls="profile"
              aria-selected="false"
            >
              {props.type == "int" ? <>Cargo</> : <>Express</>}
            </a>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div
            class="tab-pane fade show active"
            id="regular"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <div className="card-body text-center">
              <h4 className="mb-4">
                <span className="bolder-text h6">Amount: </span>
              </h4>
              <h1 className="bolder-text green-text">
                {props.status === "paid" ? (
                  <>
                    ₦
                    {props.express_type === "1" ? (
                      <>
                        {props.priceth} <br />
                        <span className="h6">
                          Payment made for {props.type == "int" ? <>Express</> : <> Express </>} Delivery
                        </span>
                      </>
                    ) : (
                      <>
                        {props.priceth} <br />
                        <span className="h6">
                          Payment made for {props.type == "int" ? <>Cargo</> : <> Regular </>} Shipment
                        </span>
                      </>
                    )}
                  </>
                ) : (
                  <>₦ {props.priceth}</>
                )}
              </h1>
            </div>
            <div className="mx-3 text-center">
              <Alert color="success">{props.status}</Alert>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="express"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <div className="card-body text-center">
              <h4 className="mb-4">
                <span className="bolder-text h6">Amount: </span>
              </h4>
              <h1 className="bolder-text green-text">
                {props.status === "paid" ? (
                  <>
                    ₦
                    {props.express_type === "1" ? (
                      <>
                        {props.expressth} <br />{" "}
                        <span className="h6">
                          Payment made for {props.type == "int" ? <>Express</> : <> Express </>} Delivery
                        </span>
                      </>
                    ) : (
                      <>
                        {props.expressth} <br />
                        <span className="h6">
                          Payment made for  {props.type == "int" ? <>Cargo</> : <> Regular </>}  Shipment
                        </span>
                      </>
                    )}
                  </>
                ) : (
                  <>₦ {props.expressth}</>
                )}
              </h1>
            </div>
            <div className="mx-3 text-center">
              <Alert color="success">{props.status}</Alert>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdPayment;
