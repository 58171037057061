import React from "react";
import GetQuoteLocal from "./GetQuoteLocal";
import GetQuoteInt from "./GetQuoteInt";

const GetQuote = (props) => {


  // Function for to call all cities


  return (
    <>
      <div className="get-quote mb-0">
        <div className="container">
          <div className="first ">
            <div className="col-md-8 mx-auto mt-3">
              <h4 className="text-center"> GET QUOTE</h4>
              <h6 className="text-center">
                {" "}
                Use Country, State and City Details to get your shipping rates
                and save valuable time.
              </h6>
            </div>
            <hr className="white" />

            <div className="">
              <ul
                class="nav nav-tabs justify-content-center "
                id="myTab"
                role="tablist"
              >
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="home-tab"
                    data-toggle="tab"
                    href="#regular"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    Local              </a>

                </li>

                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="profile-tab"
                    data-toggle="tab"
                    href="#express"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    International

                  </a>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div
                  class="tab-pane fade show active"
                  id="regular"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <GetQuoteLocal/>
                </div>
                <div
                  class="tab-pane fade"
                  id="express"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <GetQuoteInt/>
                </div>
              </div>
            </div>






          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default GetQuote;
