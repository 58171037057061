import React from 'react';


const UserFooter = () => {
    return ( 
        <>

<main class="footer">
        <footer class="page-footer font-small green">
          <div class="footer-copyright text-center py-3">
            © 2022 Copyright:
            <a href="!#"> ECLIPSE </a>
          </div>
        </footer>
      </main>

        </>
     );
}
 
export default UserFooter;